
.u1-width {
    max-width:var(--width);
    margin-left:auto;
    margin-right:auto;
    box-sizing:content-box;
    padding-left:var(--gap);
    padding-right:var(--gap);
    padding-left:calc(var(--gap) + env(safe-area-inset-left)); /* todo: test */
    padding-right:calc(var(--gap) + env(safe-area-inset-right));


    /* if used as flexbox-col-item */
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    /* width: stretch; not implemented anywhere, standard not ready yet? */
}
/* remove padding on nested width-containers */
.u1-width .u1-width {
    padding-left:0;
    padding-right:0;
}
